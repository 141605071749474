import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { respondTo } from "../_respondTo"

const HeaderWrapper = styled.header`
  width: 100%;
  height: 37vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  background-color: rgba(115, 115, 115, 0.75);

  ${respondTo.md`
    //height: 55vh;
  `}
`

const HeaderText = styled.h1`
  color: white;
  margin: 0 auto;
  font-weight: lighter;
  font-size: 8vw;
  text-align: center;
  text-transform: uppercase;

  ${respondTo.lg`
    font-size: 5vw;

`}

  &.blog {
    font-size: 3.5vh;
    ${respondTo.lg`
      font-size: 5vh;
`}
`

const HeaderSubText = styled.p`
  color: white;
  margin: 1em auto;
  font-weight: lighter;
  text-align: center;
`

const TextWrapper = styled.div`
  margin: 0 auto;
`

function Header({ headerText, headerSubText, type }) {
  return (
    <HeaderWrapper>
      <HeaderText className={type}>{headerText}</HeaderText>

      <HeaderSubText>{headerSubText}</HeaderSubText>
    </HeaderWrapper>
  )
}

PropTypes.headerText = PropTypes.string

export default Header
